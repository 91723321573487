var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "box" } },
    [
      _c("div", { staticClass: "separate" }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("a-card", {
              staticStyle: {
                "box-sizing": "border-box",
                "border-radius": "0 !important",
              },
            }),
            _c(
              "div",
              { staticClass: "box_info" },
              [
                _c("div", { attrs: { id: "searchbar" } }, [
                  _c(
                    "div",
                    [
                      _c(
                        "a-button",
                        {
                          class: _vm.Index == 16 ? "ant-btn" : "ant-btn-click",
                          staticStyle: {
                            "margin-right": "10px",
                            background: "#f39800",
                            border: "0",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.inquiry(16)
                            },
                          },
                        },
                        [_vm._v(" 全部订单 ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "a-layout",
                  [
                    _c(
                      "a-layout-content",
                      {
                        staticClass: "headbold",
                        style: {
                          background: "#fff",
                          margin: 0,
                          minHeight: "300px",
                          size: "12px",
                        },
                      },
                      [
                        _c("a-table", {
                          attrs: {
                            columns: _vm.columns,
                            "row-key": _vm.pattern,
                            "data-source": _vm.datas,
                            pagination: _vm.pagination,
                            loading: _vm.loading,
                            bordered: true,
                          },
                          on: { change: _vm.handleTableChange },
                          scopedSlots: _vm._u([
                            {
                              key: "company",
                              fn: function (datas, record) {
                                return [
                                  _c(
                                    "a-tooltip",
                                    { attrs: { placement: "top" } },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c("span", [
                                          _vm._v(_vm._s(record.company)),
                                        ]),
                                      ]),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            display: "block",
                                            width: "100px",
                                            overflow: "hidden",
                                            "text-overflow": "ellipsis",
                                            "white-space": "nowrap",
                                            cursor: "pointer",
                                          },
                                        },
                                        [_vm._v(_vm._s(record.company))]
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              },
                            },
                            {
                              key: "totalfee",
                              fn: function (datas, record) {
                                return [
                                  _c(
                                    "a-tooltip",
                                    { attrs: { placement: "top" } },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              record.ordertype == 3
                                                ? record.daibanfee
                                                : record.totalfee
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            display: "block",
                                            overflow: "hidden",
                                            "text-overflow": "ellipsis",
                                            "white-space": "nowrap",
                                            cursor: "pointer",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              record.ordertype == 3
                                                ? record.daibanfee
                                                : record.totalfee
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              },
                            },
                            {
                              key: "addtime",
                              fn: function (datas, record) {
                                return [
                                  _c(
                                    "a-tooltip",
                                    { attrs: { placement: "top" } },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c("span", [
                                          _vm._v(_vm._s(record.addtime)),
                                        ]),
                                      ]),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            display: "block",
                                            width: "90px",
                                            overflow: "hidden",
                                            "text-overflow": "ellipsis",
                                            "white-space": "nowrap",
                                            cursor: "pointer",
                                          },
                                        },
                                        [_vm._v(_vm._s(record.addtime))]
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              },
                            },
                            {
                              key: "sactions",
                              fn: function (datas, record) {
                                return [_c("span", [_vm._v("开票")])]
                              },
                            },
                            {
                              key: "ordertype",
                              fn: function (datas, record) {
                                return [
                                  record.ordertype == 1
                                    ? _c(
                                        "span",
                                        { staticStyle: { color: "#409EFF" } },
                                        [_vm._v("KEY订单")]
                                      )
                                    : _vm._e(),
                                  record.ordertype == 2
                                    ? _c(
                                        "span",
                                        { staticStyle: { color: "#67C23A" } },
                                        [_vm._v("图片处理")]
                                      )
                                    : _vm._e(),
                                  record.ordertype == 3
                                    ? _c(
                                        "span",
                                        { staticStyle: { color: "#E6A23C" } },
                                        [_vm._v("服务订单")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "actions",
                              fn: function (datas, record) {
                                return [
                                  record.status == 0
                                    ? _c("div", { staticClass: "li" }, [
                                        _vm._v("订单生成"),
                                      ])
                                    : _vm._e(),
                                  record.status == 1
                                    ? _c("div", { staticClass: "li" }, [
                                        _vm._v("支付中"),
                                      ])
                                    : _vm._e(),
                                  record.status == 2
                                    ? _c("div", { staticClass: "li" }, [
                                        _vm._v("支付成功"),
                                      ])
                                    : _vm._e(),
                                  record.status == 3
                                    ? _c("div", { staticClass: "li" }, [
                                        _vm._v("支付失败"),
                                      ])
                                    : _vm._e(),
                                  record.status == 4
                                    ? _c("div", { staticClass: "li" }, [
                                        _vm._v("已撤销"),
                                      ])
                                    : _vm._e(),
                                  record.status == 5
                                    ? _c("div", { staticClass: "li" }, [
                                        _vm._v("已退款"),
                                      ])
                                    : _vm._e(),
                                  record.status == 6
                                    ? _c("div", { staticClass: "li" }, [
                                        _vm._v("订单关闭"),
                                      ])
                                    : _vm._e(),
                                  record.status == 7
                                    ? _c("div", { staticClass: "li" }, [
                                        _vm._v("退款审核中"),
                                      ])
                                    : _vm._e(),
                                  record.status == 8
                                    ? _c("div", { staticClass: "li" }, [
                                        _vm._v("审核通过"),
                                      ])
                                    : _vm._e(),
                                  record.status == 9
                                    ? _c("div", { staticClass: "li" }, [
                                        _vm._v("审核失败"),
                                      ])
                                    : _vm._e(),
                                  record.status == 10
                                    ? _c("div", { staticClass: "li" }, [
                                        _vm._v("退款中"),
                                      ])
                                    : _vm._e(),
                                  record.status == 11
                                    ? _c("div", { staticClass: "li" }, [
                                        _vm._v("退款成功"),
                                      ])
                                    : _vm._e(),
                                  record.status == 12
                                    ? _c("div", { staticClass: "li" }, [
                                        _vm._v("退款失败"),
                                      ])
                                    : _vm._e(),
                                  record.status == 13
                                    ? _c("div", { staticClass: "li" }, [
                                        _vm._v("退款关闭"),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "action",
                              fn: function (datas, record) {
                                return [
                                  record.ordertype == 1 && record.status == 2
                                    ? _c(
                                        "a",
                                        {
                                          staticStyle: { color: "#f39800" },
                                          attrs: { href: "javascript:;" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showModal(
                                                record.id,
                                                record.status
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("申请退款")]
                                      )
                                    : _vm._e(),
                                  record.expresscompany != "" &&
                                  record.ordertype == 1 &&
                                  record.status == 2
                                    ? _c(
                                        "a",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: { href: "javascript:;" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showKuaidi(record)
                                            },
                                          },
                                        },
                                        [_vm._v("查看快递")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-modal",
                  {
                    attrs: { title: "退款原因", visible: _vm.visible },
                    on: { cancel: _vm.handleCancel },
                    scopedSlots: _vm._u([
                      {
                        key: "footer",
                        fn: function () {
                          return [
                            _c(
                              "a-button",
                              {
                                staticClass: "footerbutton",
                                attrs: { type: "primary" },
                                on: { click: _vm.handleCancel },
                              },
                              [_vm._v("取消 ")]
                            ),
                            _c(
                              "a-button",
                              {
                                staticClass: "footerbutton",
                                attrs: { type: "primary" },
                                on: { click: _vm.handleOk },
                              },
                              [_vm._v("退款 ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("div", { staticClass: "financeitem" }, [
                      _c("span", [_vm._v("本订单明细如下：")]),
                    ]),
                    _c("div", { staticClass: "financeitem" }, [
                      _vm._v("订单总金额：" + _vm._s(_vm.refunddata.totalfee)),
                    ]),
                    _c("div", { staticClass: "financeitem" }, [
                      _vm._v(
                        " 其中包含key：" +
                          _vm._s(_vm.refunddata.totalnum) +
                          "个，key总额：" +
                          _vm._s(_vm.refunddata.keyfee) +
                          "，代办服务费总额：" +
                          _vm._s(_vm.refunddata.daibanfee) +
                          "，第三方邮寄费总额：" +
                          _vm._s(_vm.refunddata.expressfee) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "financeitem" }, [
                      _vm._v(
                        "已使用数量：" + _vm._s(_vm.refunddata.usenum) + "个"
                      ),
                    ]),
                    _c("div", { staticClass: "financeitem" }, [
                      _vm._v(
                        " 可退费金额：" + _vm._s(_vm.refunddata.refundfee) + " "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "5px", padding: "5px" } },
                      [
                        _c("a-input", {
                          attrs: {
                            type: "textarea",
                            placeholder: "请输入退款原因",
                          },
                          model: {
                            value: _vm.textarea,
                            callback: function ($$v) {
                              _vm.textarea = $$v
                            },
                            expression: "textarea",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "快递信息",
            visible: _vm.dialogVisible,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "block",
              staticStyle: { height: "500px", "overflow-y": "auto" },
            },
            [
              _c(
                "el-timeline",
                _vm._l(_vm.activities, function (activity, index) {
                  return _c(
                    "el-timeline-item",
                    {
                      attrs: {
                        timestamp: activity.acceptTime,
                        placement: "top",
                      },
                    },
                    [
                      _c("el-card", [
                        _c("div", [
                          _vm._v("抵达城市：" + _vm._s(activity.acceptAddress)),
                        ]),
                        _c(
                          "p",
                          {
                            staticStyle: { color: "#666", "font-size": "12px" },
                          },
                          [_vm._v(_vm._s(activity.remark))]
                        ),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }